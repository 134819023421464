import { nanoid } from 'nanoid';
import LogService from '@/services/LogService';
import { mapState } from 'vuex';
import partnerCode from '@/enums/partnerCode';
import businessTypeConst from '@/enums/businessTypes';
import { errorStrings } from '@/constants';
import EnvService from '@/services/EnvService';
import WetterheldPartnerCodes from '@/enums/WetterheldPartnerCodes';
import setLayout from '@/mixins/setLayout';

const hotelStyleLandingPage = {
  mixins: [setLayout],
  computed: {
    ...mapState(['lang', 'settings']),
    backgroundImage() {
      const mapSizes = {
        medium: 380,
        large: 1024,
        '2048x2048': 2048,
      };
      const screen = this.screenWidth;
      const size = Object.entries(mapSizes).filter(([, value]) => screen < value)[0]?.[0];
      const url = this.wpSizes[size] || this.wpSizes['2048x2048'];
      return url ? `url(${url})` : 'unset';
    },
  },
  data() {
    return {
      autoFillServiceReady: false,
      businessRulesDownloaded: false,
    };
  },
  methods: {
    setAutoFillServiceToReady() {
      this.autoFillServiceReady = true;
      if (this.businessRulesDownloaded) {
        this.prefillFormData();
      }
    },
    setBusinessRulesDownloaded() {
      this.businessRulesDownloaded = true;
      if (this.autoFillServiceReady) {
        this.prefillFormData();
      }
    },
    async postProductPrepare(businessType) {
      if (businessType === undefined) {
        throw Error('postProductAndFind, this.businessType needs to be defined');
      }
      this.loadingId = nanoid();
      LogService.log('hotelStyleLandingPage, creating screen, loadingId', this.loadingId);
      this.$store.commit('SET_LOADING', this.loadingId);

      await this.prefillHours(businessType, this.partnerCode);
    },
    async postProductAfter(businessType) {
      const sourceTypesResult = await this.$webAppAPI.getSourceTypes(businessType);
      this.insuranceCountries = sourceTypesResult.data.insurance_country;
      this.checkCountry();
      this.checkLocationComponents();
      const findStationBody = {
        location: {
          latitude: this.formData.location.latitude,
          longitude: this.formData.location.longitude,
          address: this.formData.location.address,
          city: this.formData.location.city,
          country: this.formData.location.country,
          country_code: this.formData.location.country_code,
          zip: this.formData.location.zip,
          street: this.formData.location.street,
          house: this.formData.location.house,
          place_id: this.formData.location.id,
          input_type: 'text',
        },
      };
      if (this.formData.location.name) {
        findStationBody.location.name = this.formData.location.name;
      }
      await this.$webAppAPI.postFindStation(findStationBody);
      LogService.log('hotelStyleLandingPage,, order, finished tasks');
    },
    async postProductAndFind(businessType) {
      await this.postProductPrepare(businessType);
      await this.orderApiCall(businessType);
      await this.postProductAfter(businessType);
    },
    async postProductCouponAndFind(businessType) {
      await this.postProductPrepare(businessType);
      await this.orderApiCallCoupon(businessType, WetterheldPartnerCodes.derTourPromotion);
      await this.postProductAfter(businessType);
    },
    checkCountry() {
      LogService.log('hotelStyleLandingPage,, updateInvalidCountry start', this.formData.location);
      LogService.log('hotelStyleLandingPage,, updateInvalidCountry', this.formData.location.country_code);
      if (!this.formData.location.country_code) {
        this.showPopup(this.str.vacation.error_location_city);
        this.$store.commit('RESET_LOADING');
        throw Error('the location is a country or region');
      }
      this.invalidCountry = !this.insuranceCountries.includes(this.formData.location.country_code);
      LogService.log('hotelStyleLandingPage,, updateInvalidCountry', this.invalidCountry);
      if (this.invalidCountry) {
        this.showInvalidCountry();
      }
    },
    showInvalidCountry() {
      this.logInvalidCountry();
      this.showPopup(this.errorTextFalseCountry);
      this.$store.commit('RESET_LOADING');
      throw Error('the country send by user is not supported');
    },
    async logInvalidCountry() {
      await this.$login();
      const requestBody = {
        latitude: this.formData.location.place.geometry.location.lat(),
        longitude: this.formData.location.place.geometry.location.lng(),
        address: this.formData.location.place.formatted_address,
        country_code: this.formData.location.country_code,
        campaign_id: 'website',
        partner_code: this.partnerCode,
        dates: this.formData.dates.map((d) => this.$moment(d).format('YYYY-MM-DD')),

      };
      if (this.$route.query.utm_campaign) {
        requestBody.campaign_id = this.$route.query.utm_campaign;
      }
      LogService.log('postInvalidCountry', requestBody);
      await this.$webAppAPI.postInvalidCountry(requestBody);
    },
    checkLocationComponents() {
      const emptyLocation = Object.entries(this.formData.location || {})
        .filter(([key]) => key !== 'country')
        .every(([, val]) => !val);

      if (!emptyLocation && !this.formData.location?.city) {
        this.showPopup(this.str.vacation.error_location_city);
        this.$store.commit('RESET_LOADING');
        throw Error('the location is a country or region');
      }
    },
    async orderApiCall(businessType) {
      const dates = this.formData.dates.map((d) => this.$moment(d).format('YYYY-MM-DD'));
      const data = {
        business: {
          type: businessType,
        },
        dates,
        partner_code: this.partnerCode,
        hours: this.formData.hours,
      };
      LogService.log('partner_code', this.partnerCode);
      if (this.partnerCode === partnerCode.tui) {
        if (this.$route.query.agency_id) {
          LogService.log('add agency_id');
          data.agency_id = this.$route.query.agency_id;
        } else {
          data.show_agency_id = true;
          LogService.log('add empty agency_id');
        }
      }
      let postFunction = 'postProduct';
      if (businessType === businessTypeConst.vacation) {
        data.location = {
          latitude: this.formData.location.latitude,
          longitude: this.formData.location.longitude,
          country_code: this.formData.location.country_code,
        };
        postFunction = 'postProductWithLocation';
      }

      if (this.$route.query.utm_campaign) {
        data.promotion_id = this.$route.query.utm_campaign;
      }
      const headers = { vstemp: this.vStemp };

      await this.$webAppAPI[postFunction](data, headers).then((res) => {
        this.userStatus = res.data.user?.status?.toString();
        LogService.log('Vacation, userStatus', this.userStatus);
      }).catch((err) => {
        LogService.log(err.message);
        if (err.message === errorStrings.invalidPartnerCode) {
          const newPartnerCode = process.env.VUE_APP_SERVER_VERSION.toLowerCase();
          LogService.log('partner code module set partner code', newPartnerCode);
          this.$store.dispatch('partnerCodeModule/set_partner_code', newPartnerCode);
          if (EnvService.localOrDev()) this.$webAppAPI.showErrorPopUpMessageOnly(err.message);
          throw Error(errorStrings.invalidPartnerCode);
        } else {
          throw err;
        }
      });
    },
    async orderApiCallCoupon(businessType, partner_code) {
      const dates = this.formData.dates.map((d) => this.$moment(d).format('YYYY-MM-DD'));
      const data = {
        business: {
          type: businessType,
        },
        dates,
        partner_code,
        hours: this.formData.hours,
        coupon_code: this.coupon_code,
      };
      LogService.log('partner_code', this.partnerCode);
      data.location = {
        latitude: this.formData.location.latitude,
        longitude: this.formData.location.longitude,
        country_code: this.formData.location.country_code,
      };
      if (this.partnerCode === partnerCode.tui) {
        if (this.$route.query.agency_id) {
          LogService.log('add agency_id');
          data.agency_id = this.$route.query.agency_id;
        } else {
          data.show_agency_id = true;
          LogService.log('add empty agency_id');
        }
      }

      if (this.$route.query.utm_campaign) {
        data.promotion_id = this.$route.query.utm_campaign;
      }

      await this.$webAppAPI.postProductCoupon(data).then((res) => {
        this.userStatus = res.data.user?.status?.toString();
        LogService.log('Vacation, userStatus', this.userStatus);
      }).catch((err) => {
        const message = err?.message;
        if (typeof message !== 'undefined'
          && [errorStrings.unknownCouponCode, errorStrings.usedCouponCode].includes(message)) {
          const textToShow = errorStrings.unknownCouponCode ? this.str.vacation.error_coupon_unknown
            : this.str.vacation.error_coupon_already_used;
          this.$store.commit('RESET_LOADING');
          this.showPopup(textToShow);
        }
        throw err;
      });
    },
    showPopup(message) {
      // show the popup
      const modal = {
        id: `wet-${this.businessType}-precise-address-modal`,
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: `wet-${this.businessType}-precise-address-modal-ok`,
            title: this.str.commonApp?.ok,
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    async prefillHours(businessType, pCode) {
      let default_start_hour;
      let default_end_hour;
      LogService.log('validationRules', this.validationRules);
      if (this.validationRules.time_settings.length === 1) {
        default_start_hour = Number(this.validationRules.time_settings[0].default_start_hour);
        default_end_hour = Number(this.validationRules.time_settings[0].default_end_hour);
      } else {
        const foundElement = this.validationRules.time_settings
          .find((element) => element?.country_code === this.formData.location.country_code);
        const index = this.validationRules.time_settings.indexOf(foundElement);
        LogService.log('time settings', this.validationRules.time_settings);
        if (index === -1) {
          const sourceTypesResult = await this.$webAppAPI.getSourceTypes(businessType);
          this.insuranceCountries = sourceTypesResult.data.insurance_country;
          await this.showInvalidCountry();
          throw new Error('invalid country');
        }
        LogService.log('found country in time_settings in index', index);
        default_start_hour = Number(this.validationRules.time_settings[index].default_start_hour);
        default_end_hour = Number(this.validationRules.time_settings[index].default_end_hour);

        if (this.isSportVersLayout(pCode)
          && this.validationRules.time_settings[index].time === 'local'
          && this.validationRules.time_settings[index].min_hours <= 8
          && this.validationRules.time_settings[index].max_hours >= 8
        ) {
          default_start_hour = 8;
          default_end_hour = 16;
        }
      }

      const len = default_end_hour - default_start_hour;
      const hours = Array(len).fill().map((ii, i) => i + default_start_hour);
      this.$set(this.formData, 'hours', hours);
      LogService.log('hours prefilled');
    },
    async prefillFromBackendOnly() {
      this.defaultStartDate = this.$moment.today().toDate();
    },
    async prefillFormData() {
      const loadingId = nanoid();
      LogService.log('set prefill loading, loadingId', loadingId);
      await this.$store.commit('SET_LOADING', loadingId);
      if (this.$route.query.start_date) {
        LogService.log('start date', this.$route.query.start_date);
        const startDate = new Date(this.$route.query.start_date);
        if (!Number.isNaN(startDate.getTime())) {
          this.defaultStartDate = startDate;
        }
      }
      if (this.$route.query.end_date) {
        LogService.log('end date', this.$route.query.end_date);
        const endDate = new Date(this.$route.query.end_date);
        if (!Number.isNaN(endDate.getTime())) {
          this.defaultEndDate = endDate;
        }
      }
      if (this.$route.query.address) {
        LogService.log('address', this.$route.query.address);
        LogService.log('hotel name', this.$route.query.hotel_name ?? null);
        await this.$refs.location.$refs.places.presetAddress(
          this.$route.query.address,
          this.$route.query.hotel_name ?? null,
        );
      }
      LogService.log('query', this.$route.query);
      if (this.$route.query.promotion_code) {
        LogService.log('promotion_code', this.$route.query.promotion_code);
        this.coupon_code = this.$route.query.promotion_code;
      }
      await LogService.log('delete loading', loadingId);
      await this.$store.commit('DEL_LOADING', loadingId);
    },
    defaultRedirect() {
      let name = 'done';
      if (['5'].includes(this.userStatus) && this.$route.query?.letus !== '0') {
        name = 'letus';
      }
      this.$store.commit('RESET_LOADING');
      this.$router.push({ name, params: { ...this.$route.params } });
    },
    resize() {
      this.screenWidth = window.innerWidth;
    },
  },
};

export default hotelStyleLandingPage;
